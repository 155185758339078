import { HtagProps } from './Htag.props';
import styles from './Htag.module.css';
import cn from 'classnames';

export const Htag = ({ line, tag, children, className }: HtagProps): JSX.Element => {
    switch (tag){
        case 'h1':
            return <h1 className={cn(styles.h1, className, {
                [styles.left]: line === 'left'
            })}>{children}
            </h1>;
        case 'h2':
            return <h2 className={cn(styles.h2, className, {
                [styles.left]: line === 'left'
            })}>{children}</h2>;
        case 'h3':
            return <h3 className={cn(styles.h3, className, {
                [styles.left]: line === 'left'
            })}>{children}</h3>;
        default: 
            return <></>;
    }
};