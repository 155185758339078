import { FooterProps } from "./Footer.props";
import { ReactComponent as Logo } from '../Header/logo.svg';
import styles from './Footer.module.css'
import { format } from "date-fns";
import cn from 'classnames';
import { menuData } from "../../data/Menu";
import footerData from "../../data/lv/footer.json";
import { FaRegEnvelope } from "react-icons/fa";
import { LuPhone } from "react-icons/lu";
import { useGetProjectsQuery } from "../../slices/projectsSlice";
import Loader from "../Loader/Loader";
import { SocialBar } from "../SocialBar/SocialBar";
import { useTranslation } from "react-i18next";
import { FooterModel } from "../../models/Pages/FooterModel";
import Lightbox from "yet-another-react-lightbox";
import { useEffect, useState } from "react";
import Captions from "yet-another-react-lightbox/plugins/captions";
import { LightBoxImage } from "../../models/LightBoxImage";
import { MdOutlinePrivacyTip } from "react-icons/md";

const Footer = ({}: FooterProps):JSX.Element => {
const [lightBoxImages, setLightBoxImages] = useState<LightBoxImage[]>([]);
const [lightboxImageIndex, setLightboxImageIndex] = useState<number>(-1);
const { i18n } = useTranslation();
const { data: projects, isLoading, isError } = useGetProjectsQuery(
    { tag: 'visi', lang: "lv", limit: 12 }); 

const typedData: FooterModel[] = footerData;

useEffect(() => {
    if (projects) {
      const lightBoxImages:LightBoxImage[] = projects.map((photo) => ({
          src: photo.image,
          width: 1200,
          height: 800,
          title: photo.title,
          description: photo.description.name,
        }));

      setLightBoxImages(lightBoxImages);
    }
}, [projects]);

const handleChangeLightBoxImageIndex = (index: number): void => {
    setLightboxImageIndex(index);
};

const privacyButton = () => {
    return i18n.language === 'lv' ?  'Privatuma politika' : 'Политика конфиденциальности';
}

    return (
      <>
        <div className={styles.footer__wrapper}>
        <div className="container">
            <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <div
                className={cn(styles.logo, "element__anim", "scroll-animate")}
                data-animate="active"
                >
                <a href="/">
                    <Logo />
                </a>
                    <SocialBar />
                </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <div
                    className={cn(styles.explore, "element__anim", "scroll-animate")}
                    data-animate="active"
                >
                <h5>
                    {typedData.filter(x => x.language === i18n.language)[0].navigation}
                </h5>
                <ul>
                    {menuData.filter(x => x.lang == i18n.language)[0].menu.map((x, index) => 
                       <li key={`main-${index}`}>
                            <a href={x.href}>
                            <p>{x.name}</p>
                            </a>
                        {x.subNavItems?.map((x, subIndex) => 
                            <em key={`sub-${index}-${subIndex}`}>
                                <a href={x.href}>
                                    <p>{x.name}</p>
                                </a>
                            </em>
                        )}
                       </li>
                    )}
                </ul>
                </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <div
                    className={cn(styles.contact, "element__anim", "scroll-animate")}
                    data-animate="active"
                >
                <h5>
                    {typedData.filter(x => x.language === i18n.language)[0].contact}
                </h5>
                <ul>
                    <li>
                        <FaRegEnvelope className={styles.icon} />
                        <em>
                            {typedData.filter(x => x.language === i18n.language)[0].emailTitle}
                        </em>
                        <a href={`mailto:${typedData.filter(x => x.language === i18n.language)[0].email}`}> 
                            {typedData.filter(x => x.language === i18n.language)[0].email}
                        </a>
                    </li>
                    <li>
                        <LuPhone className={styles.icon} />
                        <em>
                            {typedData.filter(x => x.language === i18n.language)[0].phoneTitle}
                        </em>
                        <a href={`tel:${typedData.filter(x => x.language === i18n.language)[0].phone}`}>
                            {typedData.filter(x => x.language === i18n.language)[0].phone}
                        </a>
                    </li>
                    <li>
                        <MdOutlinePrivacyTip className={styles.icon} />
                        <a href="/privat-policy">
                            <p>{privacyButton()}</p>
                        </a>
                    </li>
                </ul>
                </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <div
                className={cn(styles.gallery, "element__anim", "scroll-animate")}
                data-animate="active"
                >
                <h5>{typedData.filter(x => x.language === i18n.language)[0].gallery}</h5>
                { isLoading ? (<Loader/>) : 
                isError ? (<h2>Error</h2>) : (
                    <ul>
                        {projects?.map((project, index) => (
                            <li key={index}>
                            <span
                                className={cn(styles.image__hover)}
                                onClick={() =>
                                handleChangeLightBoxImageIndex(index)
                              }
                            >
                                <img src={project.image} alt="image" />
                            </span>
                            </li>
                            )
                        )}
                    </ul>
                )}
                </div>
                <Lightbox
                    plugins={[Captions]}
                    index={lightboxImageIndex}
                    slides={lightBoxImages as LightBoxImage[]}
                    open={lightboxImageIndex >= 0}
                    close={() => handleChangeLightBoxImageIndex(-1)}
                    animation={{
                    fade: 300,
                    easing: {
                        fade: "ease-in",
                    },
                    }}
                />
            </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 align-center">
                    <div
                    className={cn(styles.copyright, "element__anim", "scroll-animate")}
                        data-animate="active"
                    >
                        © All rights reserved by LTD Orange Whale. <br/>
                                2020 - {format(new Date(), 'yyyy')}
                    </div>
                </div>
            </div>
            </div>
        </div>
      </>
    );
  };
  
  export default Footer;
  