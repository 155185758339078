import SwiperCore from "swiper";
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import SingleSlideOne from "./SingleSlide/SingleSlide";
import useActive from "../../hooks/useActive";
import up1 from "./upper.jpg";
import up2 from "./upper2.jpg";
import up3 from "./upper3.jpg";
import { FaAngleRight } from "react-icons/fa";
import { FaAngleLeft } from "react-icons/fa";
import { MainSliderProps } from "./MainSlider.props";

SwiperCore.use([EffectFade, Pagination, Navigation, Autoplay]);

const images = [
  up1, up2, up3
]

const MainSlider = ({sliderData}: MainSliderProps): JSX.Element => {
  return (
    <section className={`main-slider`}>
      <Swiper 
        slidesPerView={1}
        loop={true}
        effect="fade"
        modules={[Autoplay, Pagination, Navigation, EffectFade]}
        pagination={{
          clickable: true,
          el: '#main-slider-pagination',
          type: 'bullets',
        }}
        navigation={{
          nextEl: '#main-slider__swiper-button-next',
          prevEl: '#main-slider__swiper-button-prev',
        }}
        autoplay={{
          delay: 5000,
        }}
        >
        <div className="swiper-wrapper">
          {sliderData.map((slider, index) => (
            <SwiperSlide key={index}>
              <SingleSlideOne corousel={slider} image={images[index]}/>
            </SwiperSlide>
          ))}
        </div>
        <div className="swiper-pagination" id="main-slider-pagination"></div>
        <div className={"main-slider__nav"}>
          <div
            className="swiper-button-prev"
            id="main-slider__swiper-button-prev"
          >
            <FaAngleLeft/> 
          </div>
          <div
            className="swiper-button-next"
            id="main-slider__swiper-button-next"
          >
            <FaAngleRight/>
          </div>
        </div>
      </Swiper>
    </section>
  );
};

export default MainSlider;
