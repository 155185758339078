import { TAGS_URL } from "../constants"
import { apiSlice } from "./apiSlice"
import { ITag } from '../../../backend/models/interfaces/ITag'
import { CreateTagModel } from "../models/CreateTagModel";

export const tagsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTags: builder.query<ITag[], string>({
            query: ( lang ) => ({
                url: `${TAGS_URL}`,
                params: { lang }
            }),
            providesTags: ['Tags'],
            keepUnusedDataFor: 5
        }),
        getAllTags: builder.query<ITag[], string>({
            query: () => ({
                url: `${TAGS_URL}`,
            }),
            providesTags: ['Tags'],
            keepUnusedDataFor: 5
        }),
        addTag: builder.mutation<ITag, CreateTagModel>({
            query: (newTag) => ({
                url: `${TAGS_URL}`,
                method: 'POST',
                body: newTag
            }),
            invalidatesTags: ['Tags'],
        }),
        removeTag: builder.mutation<ITag, string>({
            query: (tagId) => ({
                url: `${TAGS_URL}/${tagId}`,
                method: 'DELETE'
            })
        })
    }),
});

export const { 
    useGetTagsQuery,
    useGetAllTagsQuery,
    useAddTagMutation,
    useRemoveTagMutation
} = tagsApiSlice;