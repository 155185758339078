import { EmailRequestBodyModel } from "../../../backend/models/interfaces/EmailRequestBodyModel";
import { EMAIL_URL } from "../constants"
import { apiSlice } from "./apiSlice";

export const emailApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        sendEmail: builder.mutation<EmailRequestBodyModel, EmailRequestBodyModel>({
            query: (emailBody) => ({
                url: `${EMAIL_URL}/send-email`,
                method: 'POST',
                body: emailBody
            }),
            invalidatesTags: ['Email'],
        }),
    }),
});

export const { 
    useSendEmailMutation
} = emailApiSlice;