import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { SubItemProps } from "./SubItem.props";
import useToggle from "../../../hooks/useToggle";
import { useLocation } from "react-router-dom";

const SubItem = ({subNavItem, handleToggleMenu, mobile}: SubItemProps): JSX.Element => {
  const location = useLocation();

  return (
      <li className={`${location.pathname === subNavItem.href ? "current" : ""}`}>
        <LinkContainer 
          to={subNavItem.href} 
          onClick={handleToggleMenu} 
        >
          <a>{subNavItem.name}{" "}</a>
        </LinkContainer>
      </li> 
  );
};

export default SubItem;


