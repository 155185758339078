import { TOURS_URL } from "../constants"
import { apiSlice } from "./apiSlice"
import { ITours3D } from '../../../backend/models/interfaces/ITours3D'
import { CreateTourModel } from "../models/CreateTourModel";

export const toursApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTours: builder.query<ITours3D[], string>({
            query: () => ({
                url: `${TOURS_URL}`
            }),
            providesTags: ['Tours'],
            keepUnusedDataFor: 5
        }),
        addTour: builder.mutation<ITours3D, CreateTourModel>({
            query: (newTour) => ({
                url: `${TOURS_URL}`,
                method: 'POST',
                body: newTour
            }),
            invalidatesTags: ['Tours'],
        }),
        removeTour: builder.mutation<ITours3D, string>({
            query: (tagId) => ({
                url: `${TOURS_URL}/${tagId}`,
                method: 'DELETE'
            })
        })
    }),
});

export const { 
    useGetToursQuery,
    useAddTourMutation,
    useRemoveTourMutation
} = toursApiSlice;