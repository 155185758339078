export const titlesLv = {
    '/': 'Stauere design',
    '/contacts': 'Kontakti - Stauere design',
    '/services': 'Pakalpojumi - Stauere design',
    '/design-project': 'Pilnais dizaina projekts - Stauere design',
    '/finished-project': 'Projekts objektiem ar gatavo apdari - Stauere design',
    '/other-services': 'Citi pakalpojumi - Stauere design',
    '/projects': 'Projekti - Stauere design',
    '/3Dtours': '3D Tūres - Stauere design',
    '/privat-policy': 'Privatuma politika - Stauere design',
  };
