import { Row, Col, Table, Button, Form } from 'react-bootstrap'
import Loader from "../../components/Loader/Loader";
import { useParams } from 'react-router-dom';
import { useAddTagMutation, useGetAllTagsQuery, useRemoveTagMutation } from '../../slices/tagsSlice';
import { FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useState } from 'react';
import PageLayout from '../../Layout/PageLayout';

const AdminTagsScreen = ({}): JSX.Element => {
  const [name, setName] = useState<string>('');
  const [lang, setLang] = useState<string>('');
  
  const {data:tags, isLoading, error, refetch } = useGetAllTagsQuery(""); 
  const [removeTag, { isLoading: loadingDelete }] = useRemoveTagMutation();
  const [addTag, { isLoading: loadingAdd }] = useAddTagMutation();

  const deleteHandler = async (id:string) => {
    if (window.confirm('Are you sure?')) {
      try {
        await removeTag(id); 
        refetch();
        toast.success('Tag deleted');
      } catch (error:any) {
        toast.error(error?.data?.message || error.error)
      }
    }
  }

  const addHandler = async () => {
    if (window.confirm('Are you sure?')) {
      try {
        await addTag({name: name, lang: lang});
        setName('');
        setLang('');
        toast.success('Tag added');
      } catch (error:any) {
        toast.error(error?.data?.message || error.error)
      }
    }
  }
  
  return(
    <>
    <PageLayout>
      <Form onSubmit={addHandler} className='mt-3'>
        <Row>
          <Col md={5}>
            <Form.Group controlId="formTagName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter tag name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                />
            </Form.Group>
          </Col>
          <Col md={5}>
          <Form.Group controlId="formTagLang">
              <Form.Label>Language</Form.Label>
              <Form.Select
                value={lang}
                onChange={(e) => setLang(e.target.value)}
                >
                <option value="">Select Language</option>
                <option value="lv">Latvian</option>
                <option value="ru">Russian</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={2} className="d-flex align-items-end">
            <Button variant="primary" type="submit">
              Add Tag
            </Button>
          </Col>
        </Row>
      </Form>
      {isLoading ? 
          (<Loader/>) : 
          error ? (<h2>Error</h2>) : (
            <Row>
          <Col>
            <h1>Tags</h1>
          </Col>
          <Table striped hover responsive className='table-sm'>
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>Language</td>
                  </tr>
                </thead>
                <tbody>
                  {tags?.map((tag) => (
                    <tr key={tag._id}>
                          <td>{tag.name}</td>
                          <td>{tag.lang}</td>
                          <td>
                          <Button 
                            variant='danger' 
                            className='btn-sm mx-2'
                            onClick={() => deleteHandler(tag._id)}
                            >
                              <FaTrash color='white'/>
                          </Button>
                          </td>
                        </tr>
                  ))}
                </tbody>
              </Table>
        </Row>)}
      </PageLayout>
    </>
  )
}

export default AdminTagsScreen