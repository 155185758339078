import { USERS_URL } from "../constants";
import { apiSlice } from "./apiSlice";

interface AuthData {
    email: string;
    password: string;
}

export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder: any) => ({
        login: builder.mutation({
            query: (data: AuthData) => ({
                url: `${USERS_URL}/auth`,
                method: 'POST',
                body: data
            }),
            keepUnusedDataFor: 5
        }),
        logout: builder.mutation({
            query: () => ({
               url: `${USERS_URL}/logout`,
               method: 'POST', 
            }),
        }),
        profile: builder.mutation({
            query: (data: any) => ({
                url: `${USERS_URL}/profile`,
                method: 'PUT', 
                body: data,
            }),
        })
    }),
});

export const { 
    useLoginMutation,
    useLogoutMutation, 
    useProfileMutation
} = usersApiSlice;