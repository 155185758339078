import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { InputComponentProps } from './InputComponent.Props';

const InputComponent: React.FC<InputComponentProps> = ({
    value,
    placeholder,
    name,
    handlerChanges: handleDescriptionChange,
}) => {
  return (
    <Row className="justify-content-center">
      <Col md={10}>
        <Form.Control
          type="text"
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={handleDescriptionChange}
        />
      </Col>
    </Row>
  );
};

export default InputComponent;
