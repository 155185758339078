import { BASE_URL, PROJECTS_URL } from "../constants"
import { apiSlice } from "./apiSlice";
import { IProjectAdminModel, IProjectModel } from "../models/IProjectModel";
import { IProjectQuery } from "../models/IProjectQuery";
import { IProjectEditModel } from "../screens/admin/AdminProjectScreen";

export interface IProjectCreateModel extends Document {
    _id: string;
}

export const projectsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProjects: builder.query<IProjectModel[], IProjectQuery>({
            query: (queryModel:IProjectQuery) => ({
                url: `${PROJECTS_URL}`,
                params: {
                    ...queryModel,
                },
            }),
            providesTags: ['Project'],
            keepUnusedDataFor: 5
        }),
        getProjectById: builder.query<IProjectModel, string | string[]>({
            query: (id) => ({
                url: `${PROJECTS_URL}/${id}`
            }),
            providesTags: ['Project'],
            keepUnusedDataFor: 5
        }),
        getProjectCount: builder.query<number, unknown>({
            query: (queryModel:IProjectQuery) => ({
                url: `${PROJECTS_URL}/totalCount`,
                params: {
                    ...queryModel,
                },
            }),
            providesTags: ['Project'],
            keepUnusedDataFor: 5
        }),
        getAdminProjects: builder.query<IProjectAdminModel[], unknown>({
            query: () => ({
                url: `${PROJECTS_URL}/admin`
            }),
            providesTags: ['Project'],
            keepUnusedDataFor: 5
        }),
        getAdminProjectsById: builder.query<IProjectAdminModel, unknown>({
            query: (id) => ({
                url: `${PROJECTS_URL}/admin/${id}`
            }),
            providesTags: ['Project'],
            keepUnusedDataFor: 5
        }),
        createProject: builder.mutation<IProjectCreateModel, unknown>({
            query: () => ({
                url: `${PROJECTS_URL}/admin/`,
                method: 'POST',
            }),
        }),
        deleteProject: builder.mutation<IProjectCreateModel, unknown>({
            query: (id) => ({
                url: `${PROJECTS_URL}/admin/${id}`,
                method: 'DELETE',
            }),
        }),
        updateProject: builder.mutation<IProjectEditModel, unknown>({
            query: (data) => ({
                url: `${PROJECTS_URL}/admin`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Project'],
        })
    }),
});

export const { 
    useGetProjectsQuery,
    useGetProjectByIdQuery,
    useGetProjectCountQuery,
    useGetAdminProjectsQuery,
    useGetAdminProjectsByIdQuery,
    useCreateProjectMutation,
    useDeleteProjectMutation,
    useUpdateProjectMutation
} = projectsApiSlice;