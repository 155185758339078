import { Container } from "react-bootstrap"
import PageLayout from "../Layout/PageLayout"
import ServiceDescription from "../components/ServiceDescription/ServiceDescription"
import { useTranslation } from "react-i18next";
import Data from './../data/finishedDesign.json';
import { ServiceData } from "../models/ServiceData";
import Faq from "../components/Faq/Faq";

const serviceData: ServiceData[] = Data;

const FinishedProjectScreen = ({}): JSX.Element =>  {
const { i18n } = useTranslation();
    return(
        <>
            <PageLayout 
                title={serviceData
                    .filter(x => x.language === i18n.language)[0].title} 
                page={serviceData
                    .filter(x => x.language === i18n.language)[0].title}
                    > 
                <Container className='body'>
                    <ServiceDescription 
                        serviceData={serviceData
                            .filter(x => x.language === i18n.language)[0]}
                        />
                    <Faq serviceData={serviceData
                            .filter(x => x.language === i18n.language)[0]}
                        />
                </Container>
            </PageLayout>
        </>
    )
}

export default FinishedProjectScreen