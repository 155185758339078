import styles from './SocialBar.module.css';
import { SocialBarProps } from './SocialBar.props';
import instagram from './instagram.svg';
import facebook from './facebook.svg';
import linkedin from './linkedin.svg';
import pinterest from './pinterest.svg';
import { Link } from 'react-router-dom';

const socials = [
    {
      id: 1,
      icon: instagram,
      href: "https://www.instagram.com/stauere.design/",
    },
    {
      id: 2,
      icon: facebook,
      href: "https://www.facebook.com/staueredesign/",
    },
    {
      id: 3,
      icon: linkedin,
      href: "https://www.linkedin.com/company/stauere-design/",
    },
    {
      id: 4,
      icon: pinterest,
      href: "https://www.pinterest.com/Stauere_Design/",
    }
]

export const SocialBar = ({}: SocialBarProps): JSX.Element => {
        return (
            <div className={styles.footer__social}>
                {socials.map(({ id, href, icon }) => (
                <Link key={id} to={href} target="_blank" rel="noopener noreferrer">
                    <img src={icon} /> 
                </Link>
                ))}
          </div>
    );
};