import { useTranslation } from "react-i18next";
import PageLayout from "../Layout/PageLayout"
import Contact from "../components/Contact/Contact"
import Data from '../data/lv/contactsPage.json';
import { ContactPageModel } from "../models/Pages/ContactsPage/ContactPageModel";

const typedData: ContactPageModel[] = Data;

const ContactScreen = ({}): JSX.Element =>  {
const { i18n } = useTranslation();
    return(
        <>
            <PageLayout 
                    title={i18n.language === "lv" ? "Kontakti" : "Контакты"} 
                    page={i18n.language === "lv" ? "Kontakti" : "Контакты"}
                    > 
                <>
                    <Contact contactData={typedData
                        .filter(x => x.language === i18n.language)[0].contactForm}/>
                </>           
            </PageLayout>
        </>
    )
}

export default ContactScreen