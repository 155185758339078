import React from 'react';
import { Row, Col, Badge, Dropdown } from 'react-bootstrap';

interface TagManagerProps {
  tags: string[];
  tagsDropdown: string[];
  deleteTagHandler: (tag: string) => void;
  handleTagSelect: (eventKey: string | null, event: React.SyntheticEvent<unknown>) => void;
}

const TagManager: React.FC<TagManagerProps> = ({ tags: lvTags, tagsDropdown: lvTagsDropdown, deleteTagHandler: deleteLvTagHandler, handleTagSelect: handleLvTagSelect }) => {
  return (
    <>
      <Row>
        <Col sm={8}>
          {lvTags.map((tag, index) => (
            <Badge pill key={index} className="mx-1">
              {tag} <span style={{ cursor: 'pointer' }} onClick={() => deleteLvTagHandler(tag)}>×</span>
            </Badge>
          ))}
        </Col>
        <Col sm={4} className="d-flex justify-content-end">
          <Dropdown onSelect={handleLvTagSelect}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Add LV tag
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {lvTagsDropdown.map((option, index) => (
                <Dropdown.Item key={index} eventKey={option}>
                  {option}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    </>
  );
};

export default TagManager;