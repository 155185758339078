import { useTranslation } from "react-i18next"
import Layout from "../Layout/Layout"
import AboutStodio from "../components/AboutStodio/AboutStodio"
import Advantages from "../components/Advantages/Advantages"
import Services from "../components/Services/Services"
import Contact from "../components/Contact/Contact"
import MainSlider from "../components/MainSlider/MainSlider"
import { Container } from "react-bootstrap"
import Data from '../data/lv/homePage.json';
import { HomePageModel } from "../models/Pages/HomePage/HomePageModel"
import Meta from "../components/Meta/Meta"

const HomeScreen = ({}): JSX.Element =>  {
const { i18n } = useTranslation();

const typedData: HomePageModel[] = Data;

    return (
        <>
            <Meta />
            <MainSlider sliderData={typedData
                .filter(x => x.lang === i18n.language)[0].corousel}/>
                <Container className='body'>
                    <Layout title="Home">
                        <>
                            <AboutStodio aboutData={typedData
                                .filter(x => x.lang === i18n.language)[0].about}/>
                            <Services serviceData={typedData
                                .filter(x => x.lang === i18n.language)[0].services}/> 
                            <Advantages advantagesData={typedData
                                .filter(x => x.lang === i18n.language)[0].advantages}/>
                            <Contact contactData={typedData
                                .filter(x => x.lang === i18n.language)[0].contactForm}/>
                        </>
                    </Layout>
                </Container>
        </>
    )
}

export default HomeScreen