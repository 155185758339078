import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import styles from './CookieConsent.module.css'
import cn from 'classnames';

const GA_TRACKING_ID = 'G-CXV21GP1XG';

const CookieConsent: React.FC = () => {
  const [consent, setConsent] = useState<boolean>(false);

  useEffect(() => {
    const savedConsent = localStorage.getItem('cookieConsent');
    if (savedConsent === 'true') {
      setConsent(true);
      initializeGA();
    }
  }, []);

  const initializeGA = () => {
    ReactGA.initialize(GA_TRACKING_ID);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  };

  const handleConsent = () => {
    setConsent(true);
    localStorage.setItem('cookieConsent', 'true');
    initializeGA();
  };

  if (consent) {
    return null;
  }

  return (
    <div className={cn(styles.bannerStyle)}>
        <span className={cn(styles.generalText)}>
          Šī vietne izmanto sīkdatnes. Piekrītot to izmantošanai, tiks uzlabota mājaslapas lietošanas pieredze.    
        </span>

          <a href='/privat-policy'>
            <u>Lasīt vairāk</u>
          </a>
          <button onClick={handleConsent} className={cn(styles.buttonStyle)}>
            Piekrītu
          </button>
    </div>
  );
};


export default CookieConsent;