import { useTranslation } from "react-i18next";
import PageLayout from "../Layout/PageLayout"
import Services from "../components/Services/Services"
import { HomePageModel } from "../models/Pages/HomePage/HomePageModel";
import Data from '../data/lv/homePage.json';

const typedData: HomePageModel[] = Data;

const ServicesScreen = ({}): JSX.Element =>  {
const { i18n } = useTranslation();
    return(
        <>
           <PageLayout 
                    title={i18n.language === "lv" ? "Pakalpojumi" : "Услуги"} 
                    page={i18n.language === "lv" ? "Pakalpojumi" : "Услуги"}
                > 
                    <Services serviceData={typedData
                        .filter(x => x.lang === i18n.language)[0].services}/> 
            </PageLayout>
        </>
    )
}

export default ServicesScreen