import React, { createContext, useContext, useState, ReactNode, FC } from 'react';
import useToggle from '../hooks/useToggle';

interface ContextType {
  menuStatus: boolean;
  toggleMenu: (value: boolean) => void;
  currentActive: string;
  setCurrentActive: (active: string) => void;
  openSearch: boolean;
  toggleSearch: () => void;
}

const RootContext = createContext<ContextType | undefined>(undefined);

interface ContextProviderProps {
  children: ReactNode;
}

export const ContextProvider: FC<ContextProviderProps> = ({ children }) => {
  const [menuStatus, toggleMenu] = useToggle(false);
  const [openSearch, toggleSearch] = useToggle(false);
  const [currentActive, setCurrentActive] = useState<string>("#home");

  const value: ContextType = {
    menuStatus,
    toggleMenu,
    currentActive,
    setCurrentActive,
    openSearch,
    toggleSearch,
  };
  
  return <RootContext.Provider value={value}>{children}</RootContext.Provider>;
};

export const useRootContext = (): ContextType => {
  const context = useContext(RootContext);
  if (!context) {
    throw new Error('useRootContext must be used within a ContextProvider');
  }
  return context;
};

export default RootContext;
