import { useTranslation } from "react-i18next";
import PageLayout from "../Layout/PageLayout"
import Data from './../data/otherServices.json';
import { Container } from "react-bootstrap";
import ServiceDescription from "../components/ServiceDescription/ServiceDescription";
import Faq from "../components/Faq/Faq";
import { ServiceData } from "../models/ServiceData";

const serviceData: ServiceData[] = Data;

const OtherProjectScreen = ({}): JSX.Element =>  {
const { i18n } = useTranslation();

    return(
        <>
            <PageLayout 
                title={serviceData
                    .filter(x => x.language === i18n.language)[0].title}
                page={serviceData
                    .filter(x => x.language === i18n.language)[0].title}
            > 
                <Container className='body'>
                    <ServiceDescription 
                        serviceData={serviceData
                            .filter(x => x.language === i18n.language)[0]}
                        />
                    <Faq serviceData={serviceData
                            .filter(x => x.language === i18n.language)[0]}
                        />
                </Container>
            </PageLayout>
        </>
    )
}

export default OtherProjectScreen