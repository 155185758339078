import { useCallback, useState } from "react";

type UseToggleReturnType = [boolean, (value?: boolean) => void];

const useToggle = (defaultValue: boolean = false): UseToggleReturnType => {
  const [toggle, setToggle] = useState<boolean>(defaultValue);

  const toggler = useCallback((value?: boolean) => {
    setToggle((prevToggle) => (typeof value === "boolean" ? value : !prevToggle));
  }, []);

  return [toggle, toggler];
};

export default useToggle;
