import { SyntheticEvent, useEffect, useState } from "react";
import { Form } from "react-bootstrap"
import FormContainer from "../../components/FormContainer/FormContainer";
import Loader from "../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../slices/usersApiSlice";
import { setCredentials } from "../../slices/authSlice";
import { toast } from "react-toastify";
import { UserInfoState } from "../../models/UserInfoState";
import PageLayout from "../../Layout/PageLayout";
import { Button } from "../../components/Button/Button";

const LoginScreen = () => {
const [email, setEmail] = useState("");
const [password, setPassword] = useState("");

const dispatch = useDispatch();
const navigate = useNavigate();

const [login, {isLoading}] = useLoginMutation();
const { userInfo } = useSelector((state: { auth: UserInfoState }) => state.auth);

useEffect(() => {
    if(userInfo){
        navigate('/')
    }
}, [userInfo, navigate])

const submitHandler =  async (e:SyntheticEvent) => {
    e.preventDefault();
    const res: any = await login({email, password}).unwrap();
    dispatch(setCredentials({...res, }));
    navigate('/');
}
    return (
        <PageLayout title="Login page">
            <FormContainer>
                <h1 className="mt-3">Sign In</h1>
                <Form onSubmit={submitHandler}>
                    <Form.Group controlId='email' className='my-3'>
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            type='email'
                            placeholder='Enter email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId='password' className='my-3'>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type='password'
                            placeholder='Enter password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                    <Button 
                        type='submit' 
                        className='mt-2 mb-3'
                        disabled={isLoading}
                    >
                        Sign In 
                    </Button>
                    {isLoading && <Loader/>}
                </Form>
            </FormContainer>
        </PageLayout>
    )
}

export default LoginScreen