import PageLayout from "../../Layout/PageLayout"
import up1 from "../../components/MainSlider/upperEmail.jpg";
import instagram from './instagram.png';
import facebook from './facebook.png';
import linkedin from './linkedin.png';
import pinterest from './pinterest.png';
import { Image } from 'react-bootstrap'
const StoryScreen = ({}): JSX.Element =>  {
    return(
        <>
           <PageLayout> 
                <div>
                    <Image src={up1} width={1220}/>
                    <Image src={instagram} width={20}/>
                    <Image src={facebook} width={20}/>
                    <Image src={linkedin} width={20}/>
                    <Image src={pinterest} width={20}/>
                </div>
            </PageLayout>
        </>
    )
}

export default StoryScreen