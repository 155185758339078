import { LinkContainer } from "react-router-bootstrap";
import MenuList from "../MenuList/MenuList";
import { useRootContext } from "../../../context/RootContext";
import { menuData } from "../../../data/Menu";
import { FaTimes } from "react-icons/fa";
import { FaRegEnvelope } from "react-icons/fa";
import { LuPhone } from "react-icons/lu";
import { ReactComponent as Logo } from '../logo.svg';
import styles from './MobileMenu.module.css'
import { SocialBar } from "../../SocialBar/SocialBar";
import { useTranslation } from "react-i18next";

const menu = menuData;

const MobileMenu = ({}) => {
  const { menuStatus, toggleMenu } = useRootContext();
  const { i18n } = useTranslation();

  const handleToggleMenu = () => {
    document.body.classList.toggle("locked");
    toggleMenu(false);
  };

  return (
    <div className={`mobile-nav__wrapper${menuStatus ? " expanded" : ""}`}>
      <div
        onClick={handleToggleMenu}
        className="mobile-nav__overlay mobile-nav__toggler"
      ></div>
      <div className="mobile-nav__content">
        <span
          onClick={handleToggleMenu}
          className="mobile-nav__close mobile-nav__toggler"
        >
          <FaTimes className={styles.icon} />
        </span>
        <div className="logo-box">
          <LinkContainer to="/" aria-label="logo image">
            <Logo color='white' width={150} />
          </LinkContainer>
        </div>
        <div className="mobile-nav__container">
          <MenuList 
          navItems={menu.filter(x => x.lang === i18n.language)[0].menu} 
          mobile={true} 
          />
        </div>
        <ul className="mobile-nav__contact list-unstyled">
          <li>
            <FaRegEnvelope className={styles.icon} />
            <a href={`mailto:stauere.design@gmail.com`}> stauere.design@gmail.com</a>
          </li>
          <li>
            <LuPhone className={styles.icon} />
            <a href={`tel:+371 28 158 033 `}>+371 28 158 033</a>
          </li>
        </ul>
        <div className="mobile-nav__top">
          <SocialBar />
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
