import { MenuListProps } from "./MenuList.props";
import NavItem from "../NavItem/NavItem";

const MenuList = ({ navItems, mobile = false }: MenuListProps):JSX.Element => {
  return (
    <ul className="main-menu__list">
      {navItems.map((navItem, index) => (
        <NavItem
          key={index}
          navItem={navItem}
          mobile={mobile}
        />
      ))}
    </ul>
  );
};

export default MenuList;
