import { ReactNode } from "react";
import PageHeader from "../components/PageHeader/PageHeader"
import Layout from "./Layout"
import { PageLayoutProps } from "./PageLayout.props";

const PageLayout = ({children, page, title} : PageLayoutProps): JSX.Element =>  {
    return(
        <>
            <PageHeader page={page} title={title}/>
            <Layout>
                {children}
            </Layout>
        </>
    )
}

export default PageLayout