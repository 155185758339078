import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import PageLayout from '../../Layout/PageLayout';
import { useAddTourMutation, useGetToursQuery, useRemoveTourMutation } from '../../slices/toursSlice';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Loader from '../../components/Loader/Loader';
import { LinkContainer } from 'react-router-bootstrap';
import { useState } from 'react';
import { toast } from 'react-toastify';

const Admin3DToursScreen = ({}): JSX.Element => {
const [addTourWindow, setAddTourWindow] = useState<boolean>(false);
const {data:tours, isLoading, error, refetch } = useGetToursQuery(''); 
const [name, setName] = useState<string>('');
const [src, setSrc] = useState<string>('');
const [showModal, setShowModal] = useState(false);

const [addTour, { isLoading: loadingAdd }] = useAddTourMutation();
const [removeTour] = useRemoveTourMutation();

const handleShowModal = () => setShowModal(true);
const handleCloseModal = () => setShowModal(false);

const onAddItem = async () => {
    try {
        await addTour({name: name, src: src});
        setName("");
        setSrc("");
        toast.success('Tour added');
      } catch (error:any) {
        toast.error(error?.data?.message || error.error)
      }

    handleCloseModal();
  };

  const deleteHandler = async (id:string) => {
    if (window.confirm('Are you sure?')) {
      try {
        await removeTour(id); 
        refetch();
        toast.success('Tour deleted');
      } catch (error:any) {
        toast.error(error?.data?.message || error.error)
      }
    }
  }

  return(
    <>
        <PageLayout>
            <Row className='align-items-center m-3'>
            <Col>
                <h1>3D Tours</h1>
            </Col>
            <Col className='text-end'>
                <Button className='btn-sm sm-3' onClick={handleShowModal}>
                    <FaEdit/> Create Projects  
                </Button>
            </Col>
            </Row>
            <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Add Item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                <Form.Group className="mb-3" controlId="formInput1">
                    <Form.Label>Input 1</Form.Label>
                    <Form.Control
                    type="text"
                    placeholder="Enter first input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formInput2">
                    <Form.Label>Input 2</Form.Label>
                    <Form.Control
                    type="text"
                    placeholder="Enter second input"
                    value={src}
                    onChange={(e) => setSrc(e.target.value)}
                    />
                </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={onAddItem}>
                    Add Item
                </Button>
            </Modal.Footer>
            </Modal>
        </PageLayout>
        {isLoading ? 
            <Loader/> : error ? 
            <p>error</p>
            : <>
                <Table striped hover responsive className='table-sm'>
                <thead>
                    <tr>
                    <td>Name</td>
                    <td>Src</td>
                    <td>Remove</td>
                    </tr>
                </thead>
                <tbody>
                    {tours?.map((tour) => (
                    <tr key={tour._id}>
                            <td>{tour.name}</td>
                            <td>{tour.src}</td>     
                            <td>
                                <Button 
                                variant='danger' 
                                className='btn-sm mx-2'
                                onClick={() => deleteHandler(tour._id)}
                            >
                                <FaTrash color='white'/>
                            </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
                </Table>
            </>
          }
    </>
  )
}

export default Admin3DToursScreen