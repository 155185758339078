import { useState } from 'react';
import styles from './ContactForm.module.css'
import { ContactFormProps } from './ContactForm.props';
import cn from 'classnames';
import { useSendEmailMutation } from '../../slices/emailSlice';
import { EmailRequestBodyModel } from '../../../../backend/models/interfaces/EmailRequestBodyModel';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader/Loader';

const ContactForm = ({contactData}: ContactFormProps): JSX.Element => {
const { i18n } = useTranslation();
const [showAlert, setShowAlert] = useState(false);
const [name, setName] = useState<string>("");
const [email, setEmail] = useState<string>("");
const [message, setMessage] = useState<string>("");
const [tel, setTel] = useState<string>("");
const [theme, setTheme] = useState<string>("");

const [sendEmail, { isLoading: loadingSending }] = useSendEmailMutation();
  const submitForm = async () => {
    if(name && email && message){
        const senderData:EmailRequestBodyModel = {
            name: name,
            to: email,
            subject: theme !== "" ? theme : "new request",
            text: message,
            phone: tel,
            lang: i18n.language
        }

        try {
            await sendEmail(senderData);
            setShowAlert(true); // Show the alert box after successful email send
          } catch (error) {
            console.error('Error sending email:', error);
        }
    }
  }

  const handleAlertConfirm = () => {
    window.location.reload();
  };

  return (
    <>
        <form id="contactForm" className={"mt-4 mb-5 cform"}>
            <div className="row">
                <div className="col-lg-6">
                    <div className={cn(styles.input__frame, styles.upper, styles.dark, "mb-3")}>
                        <label>
                            <span>{contactData.name}</span>
                            <span className={styles.required}>*</span>
                        </label>
                        <input 
                            type="text" 
                            placeholder={contactData.subName}
                            name="name" 
                            required
                            onChange={(e) =>
                                setName(() => (e.target.value))}
                            value={name} 
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className={cn(styles.input__frame, styles.upper, styles.dark, "mb-3")}>
                        <label>
                            <span>{contactData.email}</span>
                            <span className={styles.required}>*</span>
                        </label>
                        <input 
                            type="email" 
                            placeholder={contactData.subEmail}
                            name="email"
                            required
                            onChange={(e) =>
                                setEmail(() => (e.target.value))}
                            value={email} 
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className={cn(styles.input__frame, styles.upper, styles.dark, "mb-3")}>
                        <label>
                            <span>{contactData.phone}</span>
                        </label>
                        <input 
                            type="tel" 
                            placeholder={contactData.subPhone}
                            name="tel"
                            onChange={(e) =>
                                setTel(() => (e.target.value))}
                            value={tel} 
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className={cn(styles.input__frame, styles.upper, styles.dark, "mb-3")}>
                        <label>
                            <span>{contactData.theme}</span>
                        </label>
                        <input 
                            type="text" 
                            placeholder={contactData.subTheme}
                            name="theme"
                            onChange={(e) =>
                                setTheme(() => (e.target.value))}
                            value={theme} 
                        />
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className={cn(styles.input__frame, styles.upper, styles.dark, "mb-3")}>
                        <label>
                            <span>{contactData.message}</span>
                            <span className={styles.required}>*</span>
                        </label>
                        <textarea 
                            placeholder={contactData.subMessage}
                            name="message" 
                            required
                            onChange={(e) =>
                                setMessage(() => (e.target.value))}
                            value={message} 
                        />
                    </div>
                </div>
                
            </div>
            <div className="form-status alert-success  mb-5" id="contactFormStatus" style={{"display": "none"}} />
        </form>
        { loadingSending ? (<Loader/>) : (
            <div className="col-lg-12">
                <button 
                    className={cn(styles.button)} 
                    onClick={submitForm}>
                    {contactData.button}
                </button>
                {showAlert && (
                    <div className={cn(styles.alarm)}>
                        <p>{contactData.submitText}</p>
                        <button 
                            className={cn(styles.button, styles.submit)}
                            onClick={handleAlertConfirm}
                        >Ok</button>
                    </div>
                )}
            </div>
        )}
    </>
  );
};

export default ContactForm;
