export const titlesRu = {
    '/': 'Stauere design',
    '/contacts': 'Контакты - Stauere design',
    '/services': 'Услуги - Stauere design',
    '/design-project': 'Полный дизайн проект - Stauere design',
    '/finished-project': 'Дизайн проект для объектов с готовой отделкой - Stauere design',
    '/other-services': 'Другие услуги - Stauere design',
    '/projects': 'Проекты - Stauere design',
    '/3Dtours': '3D Туры - Stauere design',
    '/privat-policy': 'Политика конфиденциальности - Stauere design',
  };
