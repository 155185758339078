import { useEffect, useState } from "react";
import { Photo, PhotoAlbum, RenderContainer, RenderPhoto } from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { useGetProjectCountQuery, useGetProjectsQuery } from "../../slices/projectsSlice";
import { GalleryFullProps } from "./GalleryFull.props";
import styles from './GalleryFull.module.css'
import { useNavigate } from "react-router-dom";
import { useGetTagsQuery } from "../../slices/tagsSlice";
import Loader from "../Loader/Loader";
import TagsFilter from "../TagFilter/TagFilter";
import { LightBoxImage } from "../../models/LightBoxImage";
import { useTranslation } from "react-i18next";
import Pagination from "../Pagination/Pagination";
import { calculateTotalPages } from "../../Helpers/Helpers";

const GalleryFull = ({}: GalleryFullProps): JSX.Element => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [lightboxImageIndex, setLightboxImageIndex] = useState<number>(-1);
  const [activeTag, setActiveTag] = useState<string>("visi");
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [limit, setLimit] = useState<number>(15);
  const [lightBoxImages, setLightBoxImages] = useState<LightBoxImage[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [projectCount, setProjectCount] = useState<number>(0);
  const [skipProjects, setSkipProjects] = useState<number>(0);

  const { data: projects, isLoading, isError } = useGetProjectsQuery({
    tag: activeTag, 
    lang: i18n.language,
    limit: limit,
    skip: skipProjects,
  }); 
  
  const { data: tags, isLoading:tagsLoader, isError:tagsError } = useGetTagsQuery(i18n.language);
  const { data: loadedProjectCount } = useGetProjectCountQuery({tag: activeTag, lang: i18n.language});

  const handlerTags = (name: string) => {
      setSkipProjects(0);
      setCurrentPage(1);
      setActiveTag(name);
      if (name === 'visi') {
          navigate(`/projects`);
      }else{
          navigate(`/projects?tag=${name}`)
      }
  }

  const pageHandler = (pageNumber: number) => {
      setSkipProjects((pageNumber - 1) * limit);
      setCurrentPage(pageNumber);
      window.scrollTo({ top: 0, behavior: 'smooth' });
  } 

  const previouslyPageHandler = () => {
    const pageNumber = currentPage > 1 ? currentPage - 1 : 1;
    setSkipProjects((pageNumber - 1) * limit);
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const nextPageHandler = () => {
    const totalPages = calculateTotalPages(projectCount, 15);
    const pageNumber = currentPage >= totalPages ? totalPages : currentPage + 1;
    setSkipProjects((pageNumber - 1) * limit);
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  
  useEffect(() => {
    if (projects) {
      const newPhotos: Photo[] = projects.map((project) => ({
        src: project.image,
        key: project._id,
        width: 400,
        height: 300,
        title: project.description.name,
        description: "This is the image description",
      }));

      const lightBoxImages:LightBoxImage[] = projects.map((photo) => ({
          src: photo.image,
          width: 1200,
          height: 800,
          title: photo.title,
          description: photo.description.name,
        }));

      setPhotos(newPhotos);
      setLightBoxImages(lightBoxImages);
    }
  }, [projects]);

useEffect(() => {
  if(loadedProjectCount)
  {
    setProjectCount(loadedProjectCount)
  }
}, [loadedProjectCount])

const handleChangeLightBoxImageIndex = (index: number): void => {
  setLightboxImageIndex(index);
};

const renderContainer: RenderContainer = ({
  containerProps,
  children,
  containerRef,
}) => (
  <div className={styles.img__gallery}>
    <div ref={containerRef} {...containerProps}>
      {children}
    </div>
  </div>
);

const renderPhoto: RenderPhoto = ({
  imageProps: { alt, style, title, ...restImageProps },
}) => (
  <div
    className={styles.img__gallery} 
    style={{ width: style?.width, cursor: "pointer" }}
  >
    <div className="prettyPhotoLink" title={title}>
      <img {...restImageProps} alt={alt} style={{ width: "100%" }} />
    </div>
    <div className={styles.caption}>{title}</div>
  </div>
);

  return (
    <>
    { isLoading ? (<Loader/>) : (
      <>
          <TagsFilter 
            tags={tags} 
            tagsError={tagsError} 
            tagsLoader={tagsLoader} 
            activeTag={activeTag}
            handlerTags={handlerTags}
          /> 
          
          <div className={styles.gallery}>
            <br />
            { isLoading ? (<Loader/>) : (
              photos.length === 0 ? (
                <h3>Tag is empty</h3>
              ) : (
            <PhotoAlbum
              layout="rows"
              photos={photos}
              spacing={(containerWidth) => {
                if (containerWidth < 710) return 10;
                return 20;
              }}
              targetRowHeight={285}
              renderContainer={renderContainer}
              // renderRowContainer={renderRowContainer}
              renderPhoto={renderPhoto}
              onClick={({ index: current }) =>
                handleChangeLightBoxImageIndex(current)
              }
            />
          ))}
          </div>
          <Lightbox
            plugins={[Captions]}
            index={lightboxImageIndex}
            slides={lightBoxImages as LightBoxImage[]}
            open={lightboxImageIndex >= 0}
            zoom={{
              scrollToZoom:true,
              maxZoomPixelRatio:5
            }}
            close={() => handleChangeLightBoxImageIndex(-1)}
            animation={{
              fade: 300,
              easing: {
                fade: "ease-in",
              },
            }}
          />
          {photos.length !== 0 && 
          <div>
              <Pagination 
                totalCount={projectCount} 
                currentPage={currentPage} 
                pageHandler={pageHandler}
                previouslyPageHandler={previouslyPageHandler}
                nextPageHandler={nextPageHandler}
                />
          </div>
          }
        </>
        )}
    </>
  );
};

export default GalleryFull;
