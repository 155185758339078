import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserInfo } from "../models/UserInfo";

const storedUserInfo = localStorage.getItem('userInfo');
const initialState = {  userInfo: storedUserInfo
    ? JSON.parse(storedUserInfo) : null,
} 

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action: PayloadAction<UserInfo>) => {
            state.userInfo = action.payload;
            localStorage.setItem('userInfo', JSON.stringify(action.payload));
        },
        logout: (state) => {
            state.userInfo = null;
            localStorage.removeItem('userInfo');
        }
    },
});

export const { setCredentials, logout } = authSlice.actions;

export default authSlice.reducer;