import { Container } from 'react-bootstrap';
import { ProjectsProps } from './ProjectScreen.props';
import PageLayout from '../../Layout/PageLayout';
import GalleryFull from '../../components/GalleryFull/GalleryFull';
import { useTranslation } from 'react-i18next';

export const ProjectsScreen = ({...props}: ProjectsProps): JSX.Element => {
const { i18n } = useTranslation();

return (
   <>
    <PageLayout                      
        title={i18n.language === "lv" ? "Projekti" : "Проекты"} 
        page={i18n.language === "lv" ? "Projekti" : "Проекты"}
        >
        <Container className='body'>
            <GalleryFull/>
        </Container>
    </PageLayout>
    </>
   );
};
