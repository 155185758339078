import { Button, Row, Col, Table, Image } from 'react-bootstrap'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { 
  useCreateProjectMutation, 
  useDeleteProjectMutation, 
  useGetAdminProjectsQuery
 } from "../../slices/projectsSlice";
import Loader from "../../components/Loader/Loader";
import { LinkContainer } from 'react-router-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../../Layout/PageLayout';

const AdminProjectsScreen = ({}): JSX.Element => {
  const {data:projects, isLoading, error, refetch } = useGetAdminProjectsQuery({}); 
  const [ createProject, { isLoading: loadingCreate } ] = useCreateProjectMutation({}); 
  const [ deleteProject, { isLoading: loadingDelete }] = useDeleteProjectMutation({});

  const navigate = useNavigate();
  
  const createProjectHandler = async () => {
    if (window.confirm('Are you sure you want to create a now project?')) {
      try {
        const project = await createProject({}).unwrap();
        navigate(`${project}/edit`);
      } catch (error:any) {
        toast.error(error?.data?.message || error.error);
      }
    }
  }

  const deleteHandler = async (id:string) => {
    if (window.confirm('Are you sure?')) {
      try {
        await deleteProject(id);
        refetch();
        toast.success('Product deleted');
      } catch (error:any) {
        toast.error(error?.data?.message || error.error)
      }
    }
  }

  return (
      <>
      <PageLayout>
        <Row className='align-items-center m-3'>
          <Col>
            <h1>Projects</h1>
          </Col>
          <Col className='text-end'>
            <Button className='btn-sm sm-3' onClick={createProjectHandler}>
              <FaEdit/> Create Projects  
            </Button>
          </Col>
        </Row>
        {loadingCreate && <Loader/> }
        {loadingDelete && <Loader/> }
        {isLoading ? 
          <Loader/> : error ? 
          <p>error</p>
          : <>
            <Table striped hover responsive className='table-sm'>
              <thead>
                <tr>
                  <td>Description</td>
                  <td>Photo</td>
                  <td>Edit</td>
                </tr>
              </thead>
              <tbody>
                {projects?.map((project) => (
                  <tr key={project.projectId}>
                        {project.description?.map(desc => 
                          <td key={desc._id}>{desc.name}</td>)}
                          {project.description?.map(desc => 
                          <td key={desc._id}>{desc.altTags}</td>)}
                         {project.description?.map(desc => 
                          <td key={desc._id}>{desc.tags.map(desc => `${desc} `)}</td>)}
                        <td>
                          <Image src={project.image} fluid />
                        </td>
                        <td>
                        <LinkContainer to={`/admin/projects/${project._id}/edit`}>
                          <Button variant='light' className='btn-sm mx-2'>
                            <FaEdit />
                          </Button>
                        </LinkContainer>
                        <Button 
                          variant='danger' 
                          className='btn-sm mx-2'
                          onClick={() => deleteHandler(project._id)}
                          >
                            <FaTrash color='white'/>
                        </Button>
                        </td>
                      </tr>
                ))}
              </tbody>
            </Table>
          </>
          }
        </PageLayout>
      </>
  );
};

export default AdminProjectsScreen