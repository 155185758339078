import React from 'react';

interface SafeIframeProps {
  src: string;
  width?: string;
  height?: string;
}

const SafeIframe: React.FC<SafeIframeProps> = ({ src, width = "100%", height = "640px" }) => {
  return (
    <iframe
      src={src}
      width={width}
      height={height}
      frameBorder="0"
      allow="xr-spatial-tracking; gyroscope; accelerometer"
      allowFullScreen
      scrolling="no"
    ></iframe>
  );
};

export default SafeIframe;
