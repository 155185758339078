export const calculateTotalPages = (totalItems: number, pageSize: number): number => {
    const totalPages = Math.floor(totalItems / pageSize);
    const remainder = totalItems % pageSize;
    
    if (remainder > 0) {
        return totalPages + 1;
    } else {
        return totalPages;
    }
}
