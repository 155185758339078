import React from "react";
import { Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import up1 from "../MainSlider/upper.jpg";
import styles from './PageHeader.module.css'
import cn from 'classnames';
import { PageHeaderProps } from "./PageHeader.props";
import { useTranslation } from "react-i18next";

const PageHeader = ({page, title} : PageHeaderProps): JSX.Element => {
  const { i18n } = useTranslation();

  return (
    <section className={styles.header}>
      <div
        className={styles.header__bg}
        style={{ backgroundImage: `url(${up1})` }}
      ></div>
      <Container>
        <div className={styles.header__inner}>
          <ul className={cn(styles.breadcrumb, "list-unstyled")}>
            <li>
              <LinkContainer to="/">
                <a>
                {i18n.language === "lv" ? "SAKUMLAPA" : "Домашняя"} 
                </a>
                </LinkContainer>
            </li>{" "}
            <li>
              <span>/</span>
            </li>{" "}
            <li>{page || title}</li>
          </ul>
          <h2>{title}</h2>
        </div>
      </Container>
    </section>
  );
}

export default PageHeader;
