import { useTranslation } from "react-i18next";
import PageLayout from "../Layout/PageLayout"
import { Htag } from "../components/Htag/Htag";
import { Paragraph } from "../components/Paragraph/Paragraph";
import { Container } from "react-bootstrap";
import { PrivatPolicyModel } from "../models/Pages/ContactsPage/PrivatPolicyModel";
import Data from './../data/privatPolicy.json';

const policyData: PrivatPolicyModel[] = Data;

const PrivatPolicyScreen = ({}): JSX.Element =>  {
const { i18n } = useTranslation();
    return(
        <>
           <PageLayout 
                    title={policyData.filter(x => x.language === i18n.language)[0].title} 
                    page={policyData.filter(x => x.language === i18n.language)[0].title}
                > 
                <Container className='body mt-3 mb-3'>
                    <Htag tag="h2">
                        {policyData.filter(x => x.language === i18n.language)[0].h1}
                    </Htag>
                    <br/>
                    <Htag tag="h3">
                        {policyData.filter(x => x.language === i18n.language)[0].title1_h3}
                    </Htag>
                    <Paragraph>
                        {policyData.filter(x => x.language === i18n.language)[0].title1_p}
                    </Paragraph>
                    <br/>
                    <Htag tag="h3">
                        {policyData.filter(x => x.language === i18n.language)[0].title2_h3}
                    </Htag>
                    <Paragraph>
                        {policyData.filter(x => x.language === i18n.language)[0].title2_p}
                    </Paragraph>
                    <br/>
                    <Htag tag="h3">
                        {policyData.filter(x => x.language === i18n.language)[0].title3_h3}
                    </Htag>
                    <Paragraph>
                        {policyData.filter(x => x.language === i18n.language)[0].title3_p}
                    </Paragraph>
                    <Paragraph>
                        {policyData.filter(x => x.language === i18n.language)[0].title3_p}
                    </Paragraph>
                    <br/>
                    <Htag tag="h3">
                        {policyData.filter(x => x.language === i18n.language)[0].title4_h4}
                    </Htag>
                    <Paragraph>
                        {policyData.filter(x => x.language === i18n.language)[0].title4_p}
                    </Paragraph>
                    <Paragraph>
                        {policyData.filter(x => x.language === i18n.language)[0].title4_p2}
                    </Paragraph>
                </Container>
            </PageLayout>
        </>
    )
}

export default PrivatPolicyScreen