import { ParagraphProps } from './Paragraph.props';
import styles from './Paragraph.module.css';
import cn from 'classnames';

export const Paragraph = ({size = 'p16', children, className, ...props}: ParagraphProps): JSX.Element => {
   return (
        <p
            className={cn(styles.p, className, {
                [styles.p14]: size === 'p14',
                [styles.p16]: size === 'p16',
                [styles.p18]: size === 'p18',
                [styles.p24]: size === 'p24',
                [styles.italic]: size === 'italic',
            })}
            {...props}
        >
            {children}
        </p>
   );
};