import React, { ChangeEvent } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

interface ImageUploaderProps {
    onImageUpload: (base64String: string) => void;
  }

const ImageUploader: React.FC<ImageUploaderProps> = ({onImageUpload}) => {
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (file) {
      const reader = new FileReader();

      reader.onloadend = (event: ProgressEvent<FileReader>) => {
        onImageUpload(event.target?.result as string);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <Form.Group controlId="formFile">
      <Row  className="mt-3">
          <Col xs={3} style={{ alignSelf: 'center' }}>
            <Form.Label>
              Upload Image
            </Form.Label>
          </Col>
          <Col>
            <Form.Control 
              type="file"
              accept="image/*" 
              onChange={handleFileChange} 
            />
          </Col>
      </Row>
    </Form.Group>
  );
};

export default ImageUploader;
