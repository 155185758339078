import { Container } from "react-bootstrap";
import PageLayout from "../Layout/PageLayout"
import SafeIframe from "../components/SafeIFrame/SafeIframe";
import { useState } from "react";
import TagsFilter from "../components/TagFilter/TagFilter";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetToursQuery } from "../slices/toursSlice";
import Loader from "../components/Loader/Loader";

export interface Tours {
    name: string,
    src: string
}

const ToursScreen = ({}): JSX.Element =>  {
const {data:tours, isLoading, error, refetch } = useGetToursQuery(''); 

const { i18n } = useTranslation();
const [activeTag, setActiveTag] = useState<string>("private Home");
const navigate = useNavigate();

const handlerTags = (name: string) => {
    setActiveTag(name);
    if (name === 'visi') {
        navigate(`/3Dtours`);
    }else{
        navigate(`/3Dtours?project=${name}`)
    }
}

    return(
        <>
            <PageLayout 
                    title={i18n.language === "lv" ? "3D Tūres" : "3D Туры"} 
                    page={i18n.language === "lv" ? "3D Tūres" : "3D Туры"}
                > 
                <Container className='body'>
                {isLoading ? (<Loader/>) : 
                    error ? (<h2>Error</h2>) : (
                        <div>
                        <TagsFilter 
                            tags={tours} 
                            activeTag="" 
                            tagsLoader={false}
                            tagsError={false}
                            handlerTags={handlerTags}
                            />
                            <div className='d-flex justify-content-center align-items-center'> 
                                {tours &&
                                <SafeIframe 
                                    src={tours.filter(x => x.name === activeTag)[0].src}
                                    width="95%"
                                    height="740px"
                                />
                                }
                            </div>
                        </div>
                    )}
                </Container>
            </PageLayout>
        </>
    )
}

export default ToursScreen