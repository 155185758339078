import React from 'react';
import cn from 'classnames';
import styles from './TagFilter.module.css'; 
import Loader from '../Loader/Loader';
import { TagsFilterProps } from './TagFilter.props';

const TagsFilter = ({  tags, activeTag, tagsLoader, tagsError, handlerTags}: 
    TagsFilterProps): JSX.Element => {
  return (
    <div className={cn(styles.filtering, "mt-2 mb-3 ")}>
      <div className={styles.filter}>
        {tagsLoader ? (
          <Loader />
        ) : tagsError ? (
          <h2>Error</h2>
        ) : (
          tags?.map((tag, index) => (
            <span key={index}
                  onClick={() => handlerTags(tag.name)}
                  className={cn({[styles.active]: tag.name === activeTag})}
            >
              #{tag.name}
            </span>
          ))
        )}
      </div>
    </div>
  );
};

export default TagsFilter;
