import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { SingleSlideProps } from "./SingleSlide.props";
import styles from './SingleSlide.module.css'
import cn from 'classnames';

const SingleSlide = ({ corousel, image }: SingleSlideProps): JSX.Element => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFirstLoad(false);
    }, 2000); 

    return () => clearTimeout(timer);
  }, []);
  
  return (
    <>
      <div
        className="image-layer"
        style={{
          backgroundImage: `url(${image})`,
        }}
      ></div>

      <Container>
        <Row>
          <Col xl={12}>
            <div className={styles.slider__content}>
              <h2 
                className={cn(styles.header,
                {[styles.header__animation] : isFirstLoad}
                  )}
              >
                {corousel.title}
              </h2>
                <p className={cn(styles.header__subtitle, 
                  {[styles.subtitle__animation] : isFirstLoad})}>
                  {corousel.subTitle}
                </p>
              <LinkContainer 
                to={corousel.to} 
                className={cn(styles.button,
                {[styles.button__animation] : isFirstLoad}
                  )}
                
              >
                <p>{corousel.button}</p>
              </LinkContainer>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SingleSlide;
