import { HeaderProps } from './Header.props';
import { LinkContainer } from 'react-router-bootstrap';
import useScroll from '../../hooks/useScroll';
import MenuList from './MenuList/MenuList';
import { menuData } from '../../data/Menu';
import { FaBars } from "react-icons/fa";
import { ReactComponent as Logo } from './logo.svg';
import { useRootContext } from '../../context/RootContext';
import { FaPhoneAlt } from "react-icons/fa";
import styles from './Header.module.css'
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { RiAdminFill } from "react-icons/ri";
import { Dropdown } from 'react-bootstrap';
import { UserInfoState } from '../../models/UserInfoState';
import { useDispatch, useSelector } from 'react-redux';
import { useLogoutMutation } from '../../slices/usersApiSlice';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../slices/authSlice';

const menu = menuData;

const Header = ({}: HeaderProps): JSX.Element => {
const { i18n } = useTranslation();
const [language, setLanguage] = useState<string>(i18n.language === "lv" ? "rus" : "lat");
const { userInfo } = useSelector((state: { auth: UserInfoState }) => state.auth);

const { scrollTop } = useScroll(100)
const { toggleMenu } = useRootContext();

const handleToggleMenu = () => {
    document.body.classList.toggle("locked");
    toggleMenu(true);
};

const handleLanguageChange = () => {
    localStorage.setItem('language', language);
    setLanguage(language === "rus" ? "lat" : "rus");
    i18n.changeLanguage(language === "rus" ? "ru" : "lv")
}

const dispatch = useDispatch();
const navigate = useNavigate();
const [logoutApiCall] = useLogoutMutation();

const logoutHandler = async () => {
    try {
      await logoutApiCall({}).unwrap();
      dispatch(logout());
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  }

   return (
        <header className="main-header clearfix">
            <nav
                className={`${
                scrollTop
                    ? "stricky-header stricked-menu stricky-fixed slideInDown"
                    : "slideIn"
                } main-menu animated clearfix`}
            >
                <div
                className={`main-menu-wrapper clearfix${
                    scrollTop ? "sticky-header__content" : ""
                }`}
                >
                    <div className={styles.menu__wrapper__left}>
                        <div className={styles.wrapper__logo}>
                        <LinkContainer to="/">
                            <Logo color='white' width={150} />
                        </LinkContainer>
                        </div>
                        <div className={styles.menu__wrapper__main}>
                        <a  
                            onClick={handleToggleMenu} 
                            className="mobile-nav__toggler"
                        >
                            <FaBars />
                        </a>
                            <MenuList 
                                navItems={menu.filter(x => x.lang == i18n.language)[0].menu} 
                                mobile={false} 
                            />
                        </div>
                    </div>
                    <div className={styles.menu__wrapper__right}>
                        <div className={styles.wrapper__call}>
                            <div className={styles.menu__wrapper__lang}>
                                <div className={styles.wrapper__call}>
                                    <div className={cn(styles.call, styles.call__icon)}>
                                        <span onClick={handleLanguageChange}>{language}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={cn(styles.call, styles.call__icon)}>
                                <span>
                                    <a href={`tel: +371 28 158 033`}>
                                        <FaPhoneAlt  size={20}/>
                                    </a>
                                </span>
                            </div>
                            <div className={styles.call__number}>
                                <h5>
                                    <a href={`tel: +371 28 158 033`}>+371 28 158 033</a>
                                </h5>
                            </div>
                            {userInfo?.isAdmin && 
                                <Dropdown>
                                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                        <RiAdminFill size={20}/>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item>
                                            <LinkContainer to="/admin/tags">
                                                <span>Tags</span>
                                            </LinkContainer>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                            <LinkContainer to="/admin/projects">
                                                <span>Projects</span>
                                            </LinkContainer>
                                        </Dropdown.Item>

                                        <Dropdown.Item>
                                            <LinkContainer to="/admin/3Dtours">
                                                <span>3Dtours</span>
                                            </LinkContainer>
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                            <Dropdown.Item onClick={logoutHandler}>
                                            Logout
                                            </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                        </div>
                    </div>
                </div>
            </nav>
        </header>
   );
};

export default Header


