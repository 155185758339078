import React, { useState } from 'react';
import styles from './Faq.module.css'
import cn from 'classnames';
import { FaAngleDown } from "react-icons/fa";
import { FaAngleUp } from "react-icons/fa";
import { FaqProps } from './Faq.props';

interface faqModel {
    title: string;
    description: string;
}

const Faq = ({serviceData}: FaqProps): JSX.Element => {
  const [showQues, setQues] = useState<number>(1);

  const openQuestion = (value: number) => {
    setQues(value);
  };

  return (
    <>
       <section className={cn(styles.faq__area, "mb-4")}>
                <div className="container">
                    <div className="row">
                    {serviceData.faq.map((faq, index) => (
                        <div className="col-lg-6">
                            <div
                                className={cn("wow fadeInRight mt-30")}
                                data-wow-duration="1500ms"
                            >
                                <div
                                    className={cn(styles.faq, "animated fadeIn")}
                                >
                                    <div
                                        onClick={() => openQuestion(index)}
                                        className={
                                            cn(styles.dropdown, 
                                            {[styles.active] : showQues === index}
                                        )}>
                                        <div className={styles.dropdown__inner}>
                                            <div className={styles.title}>
                                                <h4>{faq.title}</h4>
                                                <span>
                                                    {showQues === index ? 
                                                    (<FaAngleDown className={styles.arrow}/>) 
                                                    : (<FaAngleUp className={styles.arrow}/>)
                                                    }
                                                    </span>
                                            </div>
                                            <div
                                                className={styles.description}
                                                style={{
                                                    display: showQues === index ? 'block' : 'none',
                                                }}
                                            >
                                                <div className={styles.inner}>
                                                    <p>
                                                        {faq.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}                        
                        <div className="col-lg-12">
                            <div className="text-center pt-4">
                                <p>
                                    {serviceData.contact}{"  "}
                                    <a className={styles.contact} href='/contacts'>{serviceData.contactPush}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </>
  );
};

export default Faq;
