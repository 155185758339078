import ContactForm from '../ContactForm/ContactForm';
import styles from './Contact.module.css'
import { ContactProps } from './Contact.props';
import cn from 'classnames';

const Contact = ({contactData}: ContactProps): JSX.Element => {
    return (
      <>
        <section>
          <div className={cn("container", 'pt-5', 'pb-4')}>
            <div className="row justify-content-between">
              <div className="col-lg-4">

                <div className="mb-4">
                  <span className={cn(styles.suptitle, styles.upper, 'mb-3')}>
                  {contactData.touchModel.subtitle}
                  </span>
                  <h2 className={cn(styles.upper, 'mb-3')} >
                    {contactData.touchModel.title}
                  </h2>
                  <div className={cn(styles.divider, styles.upper, 'mb-3')} />
                  {contactData.touchModel.touchItem.map((item, index) => (
                    <ul className={cn(styles.list, styles.dark, styles.upper, 'mb-3')} key={index}>
                      {item.list.map((list, list_key) => (
                        <li key={list_key}>{list}</li>
                      ))}
                    </ul>
                  ))}
                </div>
  
              </div>
              <div className="col-lg-7">
                <ContactForm contactData={contactData.form}/>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };
  
  export default Contact;
  