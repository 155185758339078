import styles from './Advantages.module.css'
import { AdvantagesProps } from './Advantages.props';
import cn from 'classnames';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const Advantages = ({advantagesData}: AdvantagesProps): JSX.Element => {
  return (
    <>
      <section>
        <div className={cn("container", 'pt-5', 'pb-4')}>

          <div className="row">
            <div className="col-12">
              <div className="text-center mb-5">
                <span 
                  className={cn(styles.suptitle, "align-items-center", styles.upper, "mb-3")}
                >{advantagesData.title}</span>
                <h2>{advantagesData.subTitle}</h2>
                <p>{advantagesData.question}</p>
              </div>
            </div>
            {advantagesData.advantageItem.map((item, index) => (
              <div key={index} className="col-md-6 col-lg-6">
                <div className={cn(styles.advantage, styles.icon__box, "text-center", "mb-5")}>
                  <div className={styles.titleBox}>
                    <h4 className={cn(styles.upper)}>{item.title}</h4>
                  </div>
                  <div className={cn(styles.icon, styles.icon__border, "mb-4")}>
                    <MdKeyboardDoubleArrowRight/>
                  </div>
                  <p className={styles.paragraph}>{item.message}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Advantages;
