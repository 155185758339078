import './App.css'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { Outlet, useLocation } from 'react-router-dom'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import { useEffect } from 'react'
import { scrollAnimation } from './utils/utils'
import CookieConsent from './components/CookieConsent/CookieConsent'
import { useTranslation } from 'react-i18next'
import { titlesLv } from './data/lv/titlesLv'
import { titlesRu } from './data/ru/titlesRu'

const usePageTitle = (titles: Record<string, string>) => {
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    const title = titles[path] || 'Stauere design';
    document.title = title;
  }, [location, titles]);
};

function App() {
const { i18n } = useTranslation();
usePageTitle(i18n.language === 'lv' ?  titlesLv : titlesRu);

useEffect(() => {
  scrollAnimation();
  window.addEventListener("scroll", scrollAnimation);
}, []);


  return (
    <>
      <div className="App">
        <CookieConsent />
        <Header />
          <Outlet />
          <Footer />
          <ToastContainer/> 
      </div>
    </>

  );
}

export default App;


