import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/swiper/swiper-bundle.min.css'

import './styles/globals.css'
import './styles/style.css'
import './styles/responsive.css'
import './styles/magnific-popup.css'

//import './styles/bootstrap.custom.css';
import { 
  createBrowserRouter, 
  Route, 
  createRoutesFromElements, 
  RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import { ProjectsScreen } from './screens/ProjectScreen/ProjectsScreen';
import HomeScreen from './screens/HomeScreen';
import LoginScreen from './screens/admin/LoginScreen';
import PrivateRoute from './components/Routes/PrivateRoute';
import AdminProjectsScreen from './screens/admin/AdminProjectsScreen';
import AdminProjectScreen from './screens/admin/AdminProjectScreen';
import AdminTagsScreen from './screens/admin/AdminTagsScreen';
import DesignProjectScreen from './screens/DesignProjectScreen';
import FinishedProjectScreen from './screens/FinishedProjectScreen';
import OtherProjectScreen from './screens/OtherProjectScreen';
import './i18n';
import { ContextProvider } from './context/RootContext';
import ServicesScreen from './screens/ServicesScreen';
import ContactScreen from './screens/ContactScreen';
import { HelmetProvider } from 'react-helmet-async';
import ToursScreen from './screens/3dToursScreen';
import StoryScreen from './screens/StoryScreen/StoryScreen';
import PrivatPolicyScreen from './screens/PrivatPolicyScreen';
import Admin3DToursScreen from './screens/admin/Admin3DToursScreen';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="" element={<App />}>
        <Route index={true} path="/" element={<HomeScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/projects" element={<ProjectsScreen />} />
        <Route path="/design-project" element={<DesignProjectScreen />} />
        <Route path="/finished-project" element={<FinishedProjectScreen />} />
        <Route path="/other-services" element={<OtherProjectScreen />} />
        <Route path="/services" element={<ServicesScreen/>} />
        <Route path="/contacts" element={<ContactScreen />} />
        <Route path="/3Dtours" element={<ToursScreen />} />
        <Route path="/story" element={<StoryScreen />} />
        <Route path="/privat-policy" element={<PrivatPolicyScreen />} />

        <Route path="" element={<PrivateRoute />}>
          <Route path="/admin/projects/:id/edit" element={<AdminProjectScreen />} />
          <Route path="/admin/projects" element={<AdminProjectsScreen />} />
          <Route path="/admin/tags" element={<AdminTagsScreen />} />
          <Route path="/admin/3Dtours" element={<Admin3DToursScreen />} />
        </Route>
    </Route>
  )
)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ContextProvider>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </ContextProvider>    
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
