import { Helmet } from 'react-helmet-async' 
import { MetaProps } from './Meta.props'

const Meta = ({ title, description, keywords }: MetaProps): JSX.Element =>{
  return (
    <Helmet>
        <title>{title}</title>
        <meta name='description' content={description}/>
        <meta name='keywords' content={keywords}/>
    </Helmet>
  )
}

Meta.defaultProps = {
    title: 'Stauere design interjera dizaina studija',
    description: 'Mēs uzskatām, ka interjera dizains ir vairāk nekā lieliska funkcionalitāte un estētika. Mūsu mērķis ir padarīt jūsu mājas interjeru par jūsu personības atspoguļojumu. Jūsu mājām jābūt tādai vietai ar ko jūs un jūsu ģimene lepojaties un kurā patīk pavadīt laiku.',
    keywords: 'interjera dizaina studija'
}

export default Meta