import { AboutStodioProps } from './AboutStodio.props';
import { Image } from "react-bootstrap";
import story from './ourStory2.png'
import styles from './AboutStodio.module.css'
import cn from 'classnames';
import { MdKeyboardArrowRight } from "react-icons/md";
import signature from './signature.png'

const AboutStodio = ({aboutData}: AboutStodioProps): JSX.Element => {
  return (
    <section className={cn(styles.section, 'container-fluid')}>
      <div className='row m-2-hor'>
        <div className='col-md-6 pt-5'>
          <div className={styles.col__home}>
            <div className={styles.thumb}>
              <Image className="img-fluid" src={story} alt="" />
            </div>
          </div>
        </div>

        <div className='col-md-6'>
          <div className={styles.flex__center}>
            <div className='col-home mt-md-0 mt-5'>
              <div className={styles.heading}>{aboutData.title}</div>
              <div className={styles.content}>
                {aboutData.text}
              </div>
              <ul className={styles.story_list}>
                {aboutData.benefits.map((item, index) => (
                  <li key={index}><MdKeyboardArrowRight className={styles.icon}/>{item}</li>
                ))}
              </ul>
              <Image
                  className={styles.image}
                  src={signature}  
                  alt="Picture of the author"
                  width={150}
                  height={117}
              />
            </div>
          </div>
        </div>

      </div>
    </section>
  );
};

export default AboutStodio;
