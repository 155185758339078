import { Col, Row } from "react-bootstrap"
import { ParinationProps } from "./Pagination.props"
import cn from 'classnames';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import styles from './Pagination.module.css'; 
import { calculateTotalPages } from "../../Helpers/Helpers";

const Pagination = ({ totalCount, currentPage, pageHandler, previouslyPageHandler, nextPageHandler }: ParinationProps): JSX.Element => {
    
const renderPaginationButtons = () => {
    const buttons = [];
    const pages = generatePagination(currentPage, calculateTotalPages(totalCount, 15));

    for (let i = 0; i < pages.length; i++) {
        buttons.push(
            <div key={i} className={cn(
                styles.blog__pagination__button, 
                {[styles.active]: pages[i] === currentPage})}
                onClick={() => typeof pages[i] === 'number' && pageHandler(pages[i] as number)}
                >
                    {pages[i]}
            </div>
        );
    }
    return buttons;
};

function generatePagination(currentPage: number, totalPages: number) {
    const pages:(string | number)[] = [];
    
    pages.push(1);

    if (totalPages === 1) {
        return pages;
    }

    if (currentPage > 3) {
        pages.push('...');
    }

    if (currentPage > 2) {
        pages.push(currentPage - 1);
    }
    if (currentPage !== 1 && currentPage !== totalPages) {
        pages.push(currentPage);
    }
    if (currentPage < totalPages - 1) {
        pages.push(currentPage + 1);
    }

    if (currentPage < totalPages - 2) {
        pages.push('...');
    }
    
    if (totalPages) {
        pages.push(totalPages);
    }

    return pages;
}
  
return (
    <>
    <Row>
        <Col lg={12}>
            <div className={cn(styles.blog__pagination)}>
                {currentPage !== 1 && 
                <IoIosArrowBack 
                    size={30} 
                    className={cn(styles.arrows)}
                    onClick={previouslyPageHandler}
                />}
                
                {renderPaginationButtons()}
                {currentPage !== calculateTotalPages(totalCount, 15) && 
                    <IoIosArrowForward 
                    size={30} 
                    className={cn(styles.arrows)}
                    onClick={nextPageHandler}
                />}
  
            </div>
        </Col>
    </Row>
    </>
)}

export default Pagination;
