import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    lv: {},
    ru: {}
};
const savedLanguage = localStorage.getItem('language') === "rus" ? 'ru' : 'lv';

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: savedLanguage,
    keySeparator: false,
    interpolation: {
        escapeValue: false
    }
  });

export default i18n;
