import React from 'react';
import { ServiceDescriptionProps } from './ServiceDescription.props';
import { ContentModel } from '../../models/ServiceData';
import cn from 'classnames';
import styles from './ServiceDescription.module.css'
import { MdOutlineCheckCircleOutline } from "react-icons/md";
import { RxDot } from "react-icons/rx";

const ServiceDescription: React.FC<ServiceDescriptionProps> = ({ serviceData }) => {
  return (
    <section>
      <div className={cn("container", 'pt-5', 'pb-4')}>
        <div className="row justify-content-between">
          <div className="col-lg-7">
            {serviceData.description.map((item, key) => (
              <React.Fragment key={key}>
                {item.layout === 1 && (
                  <>
                    <h2 className={cn(styles.upper, "mb-5")}>{item.title}</h2>
                    <div className={cn("mb-5")} dangerouslySetInnerHTML={{ __html: item.content }} />
                  </>
                )}
                {item.layout === 2 && (
                  <>
                    <h2 className={cn(styles.upper, "mb-5")}>{item.title}</h2>
                    <div className="row justify-content-between">
                      <div className="col-lg-6">
                        <div className="mb-3" dangerouslySetInnerHTML={{ __html: item.content }} />
                      </div>
                      <div className="col-lg-5">
                        <ul className={cn(styles.icon__list, "mb-5")}>
                          {item.list?.map((list_item, list_key) => (
                              <li key={`service-description-${key}-list-${list_key}`}>
                              <MdOutlineCheckCircleOutline className={styles.icon__list_image}/>
                                <p>
                                  {list_item}
                                </p>
                              </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </>
                )}
                {item.divider && <div className={cn(styles.divider, styles.upper, "mb-5")} />}
              </React.Fragment>
            ))}
          </div>

          <div className="col-lg-5">
            <div className={cn(styles.sidebar__frame, "mb-5")}>
              {serviceData.sidebarTitle && (
                <h2 className={cn(styles.upper, "mb-5")}>
                  {serviceData.sidebarTitle}
                </h2>
              )}

              {serviceData.sidebar.map((item, key) => (
                <React.Fragment key={`service-sidebar-${key}`}>
                  {item.layout === "list" && (
                    <div className='mb-2'>
                      <h6 className={cn(styles.upper, "mb-3")}>
                        {item.title}
                      </h6>
                        {item.content.map((list_item, index) => (
                          <div className={styles.list}>
                            <RxDot className={styles.listIcon}/>
                            <span key={index}>{list_item as string}</span>
                          </div>
                        ))}
                    </div>
                  )}
                  {item.layout === "values" && (
                    <>
                      <div className="row">
                        {item.content.map((list_item, list_key) => (
                          <div className="col-6" key={`service-sidebar-${key}-values-${list_key}`}>
                            <div className={cn(styles.counter, "mb-3")}>
                              <h5 className="mb-1">
                                {(list_item as ContentModel).afterValue}
                                </h5>
                              <p className="mil-dark" 
                                dangerouslySetInnerHTML={{ __html: (list_item as ContentModel).label }} 
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {key + 1 < serviceData.sidebar.length 
                    && <div className={cn(styles.divider, styles.upper, "mb-3")} 
                  />}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceDescription;
