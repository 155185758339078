import { Row, Col, Button, Image, Form } from 'react-bootstrap'
import { useGetAdminProjectsByIdQuery, useUpdateProjectMutation } from "../../slices/projectsSlice";
import Loader from "../../components/Loader/Loader";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetTagsQuery } from '../../slices/tagsSlice';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ImageUploader from '../../components/ImageUploader/ImageUploader';
import TagManager from '../../components/TagEditor/TagManager';
import InputComponent from '../../components/InputComponent/InputComponent';
import PageLayout from '../../Layout/PageLayout';

export interface IProjectEditModel {
  _id: string;
  image: string;
  description: IProjectDescriptionEditModel[];
}

export interface IProjectDescriptionEditModel {
  name: string;
  altTags: string;
  tags: string[];
  language: string;
}

const AdminProjectScreen = ({}): JSX.Element => {
  const [imageData, setImageData] = useState<string>("")
  const [lvTags, setLvTags] = useState<string[]>([])
  const [ruTags, setRuTags] = useState<string[]>([])
  const [lvTagsDropdown, setLvTagsDropdown] = useState<string[]>([])
  const [ruTagsDropdown, setRuTagsDropdown] = useState<string[]>([])
  const [lvDescription, setLvDescription] = useState<string>("")
  const [ruDescription, setRuDescription] = useState<string>("")
  const [lvAltTag, setLvAltTag] = useState<string>("")
  const [ruAltTag, setRuAltTag] = useState<string>("")
  const navigate = useNavigate();
  const { id: projectId } = useParams();

  const {data, isLoading, error, refetch } = useGetAdminProjectsByIdQuery(projectId ? projectId : ""); 
  const { data: tagsLv, isLoading:lvTagsLoader, isError:lvTagsError } = useGetTagsQuery("lv");
  const { data: tagsRu, isLoading:ruTagsLoader, isError:ruTagsError } = useGetTagsQuery("ru");
  const [updateProduct, { isLoading: loadingUpdate }] = useUpdateProjectMutation();

const handleImageData = (base64String: string) => {
  setImageData(base64String)
};

useEffect(() => {
  if (tagsLv) {
    const newNames = tagsLv.map(tag => tag.name);
    setLvTagsDropdown(newNames);
  }
}, [tagsLv]);

useEffect(() => {
  if (tagsRu) {
    const newNames = tagsRu.map(tag => tag.name);
    setRuTagsDropdown(newNames);
  }
}, [tagsRu]);

useEffect(() => {
  if (data?.description && data.description.length > 0) {
    setLvDescription(data.description[0].name)
    setLvAltTag(data.description[0].altTags)
    if (data.description[0]?.tags) {
      setLvTags(data.description[0].tags);
    }
    if (data.description.length > 1 && data.description[1]?.tags) {
      setRuDescription(data.description[1].name)
      setRuTags(data.description[1].tags)
      setRuAltTag(data.description[1].altTags)
    }
  }

  if (data?.image){
    setImageData(data.image);
  }
}, [data]);

const handleLvTagSelect = (eventKey:string | null) => {
  if (eventKey) {
    if (lvTags.includes(eventKey)) {
      toast.error(`${eventKey} exist`);
    }else{
      setLvTags([...lvTags, eventKey])
      toast.success(`${eventKey} added`);
    }
  }
};

const handleRuTagSelect = (eventKey:string | null) => {
  if (eventKey) {
    if (ruTags.includes(eventKey)) {
      toast.error(`${eventKey} exist`);
    }else{
      setRuTags([...ruTags, eventKey])
      toast.success(`${eventKey} added`);
    }
  }
};

const deleteLvTagHandler = (lvTagToRemove:string) => {
  if (lvTagToRemove) {
    const newNames = lvTags.filter(tag => tag !== lvTagToRemove);
    setLvTags(newNames);
  }
}

const deleteRuTagHandler = (ruTagToRemove:string) => {
  if (ruTagToRemove) {
    const newNames = ruTags.filter(tag => tag !== ruTagToRemove);
    setRuTags(newNames);
  }
}

const handleLvDescriptionChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  setLvDescription(e.target.value);
}

const handleRuDescriptionChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  setRuDescription(e.target.value);
}

const handleLvAltTagChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  setLvAltTag(e.target.value);
}

const handleRuAltTagChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  setRuAltTag(e.target.value);
}

const submitHandler = async (e: React.ChangeEvent<HTMLFormElement>) => {
  e.preventDefault();
  const updateProject:IProjectEditModel = {
      _id: projectId ? projectId : "",
      image: imageData,
      description: [
        {
          name: lvDescription,
          language: 'lv',
          tags: lvTags,
          altTags: lvAltTag,
        },
        {
          language: 'ru',
          tags: ruTags,
          altTags: ruAltTag,
          name: ruDescription
        }
      ]
  };

  const result:any = await updateProduct(updateProject);
  if (result.error) {
     toast.error(result.error)
   } else {
     refetch();
     toast.success('Product updated');
     navigate('/admin/projects');
   }
}
  return(
    <>
    <PageLayout>
      <Link to='/admin/projects' className='btn btn-light my-3'>
          Go Back
      </Link>
      <h1>Update Project</h1>
        {loadingUpdate && <Loader/>}
        {isLoading ? 
          (<Loader/>) : 
          error ? (<h2>Error</h2>) : (
            <Form onSubmit={submitHandler}>
          <h2>Image</h2>
          <Image src={imageData} fluid key={imageData} />
          <ImageUploader onImageUpload={handleImageData}/>
          <h2>Descriptions</h2>
            <InputComponent 
              value={lvDescription}
              placeholder='Description lv'
              handlerChanges={handleLvDescriptionChange}
              />
            <InputComponent 
              value={ruDescription}
              placeholder='Description ru'
              handlerChanges={handleRuDescriptionChange}
              />
          <h2>Tags</h2>
            <TagManager 
              tags={lvTags} 
              tagsDropdown={lvTagsDropdown} 
              deleteTagHandler={deleteLvTagHandler} 
              handleTagSelect={handleLvTagSelect}
              />
            <TagManager 
                tags={ruTags} 
                tagsDropdown={ruTagsDropdown} 
                deleteTagHandler={deleteRuTagHandler} 
                handleTagSelect={handleRuTagSelect}
                />
          
          <h2>Alt tags</h2>
          <InputComponent 
              value={lvAltTag}
              placeholder='Alt tag lv'
              handlerChanges={handleLvAltTagChange}
              />
            <InputComponent 
              value={ruAltTag}
              placeholder='Alt tag ru'
              handlerChanges={handleRuAltTagChange}
              />
            <Button
                type='submit'
                variant='primary'
                className='my-2'
                >
                Update
            </Button>
            </Form>
          )}
        </PageLayout>
    </>
  )
}

export default AdminProjectScreen