import { Outlet, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { UserInfoState } from '../../models/UserInfoState';

const PrivateRoute = () => {
    const { userInfo } = useSelector((state: { auth: UserInfoState }) => state.auth);

  return (
    userInfo ? <Outlet/> : <Navigate to='/' replace />
  )
}

export default PrivateRoute